<template>
  <div>
    <!-- 面包屑 -->
    <el-breadcrumb v-show="userInfo.timeIsOver==0" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>系统充值</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 内容 -->
    <div style="display:flex;justify-content: center;align-items:center;">
      <el-card style="width: 1000px;">
        <div style="margin: 0 auto;width: 800px;">
          <!-- 标题 -->
          <div style="display:flex;justify-content:center;font-size: 26px;font-weight: 500;">
            <div
              style="width: 200px;height:30px;border-radius:15px;display:flex;font-size: 16px;justify-content: center;font-weight: 600;">
              <div @click="clickTab(1)" :class="currentTab==1?'currentTab':''"
                style="border-radius: 15px 0px 0 15px;border-right: none;" class="tabItem">
                会员充值
              </div>
              <div @click="clickTab(2)" :class="currentTab==2?'currentTab':''"
                style="border-radius: 0 15px 15px 0;border-left: none;" class="tabItem">
                短信充值</div>
            </div>
          </div>
          <!-- 个人信息 -->
          <div style="margin-top:20px;border-bottom:1px solid #eee;padding-bottom:20px;">
            <div style="display:flex;justify-content: space-between;">
              <div>购买账号：{{user.name}}</div>
              <div v-if="currentTab==1">会员到期时间：{{user.expirationTime}}</div>
              <div v-if="currentTab==2">短信剩余条数：{{user.messCount}}</div>
            </div>
            <div style="margin-top:10px">
              手机号：{{user.phone}}
            </div>
          </div>
          <!-- 会员充值列表 -->
          <div v-if="currentTab==1" style="margin-top:30px;width:800px;">
            <div @click="currentItem(item,index)" v-for="(item,index) in list" :key="index"
              style="padding:12px 20px;box-sizing: border-box;height: 80px;"
              :style="currentIndex==index?'border: 2px solid #FF4400;':'border:1px solid #eee;'">
              <!-- top -->
              <div style="display:flex;">
                <div style="margin-right:30px;color: #333;font-size: 16px;width: 50px;">
                  {{item.dateFormats}}个月</div>
                <span
                  style="padding:1px 5px;background-image:linear-gradient(90deg, #FF2D3D 0%, #FF7544 100%);color:#fff;font-size: 12px;border-radius: 100px;display: flex;align-items: center;justify-content: center;">限时{{item.discount}}折</span>
                <div style="flex:1;display: flex;justify-content: flex-end;color: #FF6600;font-size: 18px;">
                  ￥{{Number(item.currentPrice).toFixed(2)}}</div>
              </div>
              <!-- bottom -->
              <div style="display:flex;justify-content: space-between;margin-top: 8px;">
                <div style="color: #666;font-size: 12px;">
                  {{(item.currentPrice/item.dateFormats).toFixed(1)}}元/月
                </div>
                <div style="text-decoration: line-through;font-size: 14px;color: #999;">
                  ￥{{item.masterCopy}}
                </div>
              </div>
            </div>

          </div>
          <!-- 短信充值列表 -->
          <div v-else style="margin-top:30px;width:800px;">
            <div @click="currentItem(item,index)" v-for="(item,index) in list2" :key="index"
              style="padding:12px 20px;box-sizing: border-box;height: 80px;"
              :style="currentIndex==index?'border: 2px solid #FF4400;':'border:1px solid #eee;'">
              <!-- top -->
              <div style="display:flex;">
                <div style="margin-right:0px;color: #333;font-size: 16px;width: 80px;">
                  {{item.dateFormats}}条</div>
                <span
                  style="padding:1px 5px;background-image:linear-gradient(90deg, #FF2D3D 0%, #FF7544 100%);color:#fff;font-size: 12px;border-radius: 100px;display: flex;align-items: center;justify-content: center;">赠送{{item.discount}}条</span>
                <div style="flex:1;display: flex;justify-content: flex-end;color: #FF6600;font-size: 18px;">
                  ￥{{Number(item.currentPrice).toFixed(2)}}</div>
              </div>
              <!-- bottom -->
              <div style="display:flex;justify-content: space-between;margin-top: 8px;">
                <div style="color: #666;font-size: 12px;">
                  {{(item.currentPrice/(Number(item.dateFormats)+Number(item.discount))).toFixed(2)}}元/条
                </div>
                <div style="text-decoration: line-through;font-size: 14px;color: #999;">
                  ￥{{item.masterCopy}}
                </div>
              </div>
            </div>

          </div>

          <!-- 优惠卷 -->
          <div style="display:flex;justify-content:flex-end;align-items:center;margin-top:10px">
            <div @click="clickYHJ" style="font-size: 14px;color: #333;">我有优惠券，点此兑换</div>
            <div style="font-size: 14px;color: #333;margin-left: 24px;">优惠卷：</div>
            <div>
              <el-select size="mini" v-model="value" placeholder="请选择">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div style="margin-left: 16px;font-size: 18px;padding-right: 20px;">-￥0.00</div>
          </div>
          <!-- 支付 -->
          <div
            style="background: #FEFCEF;border: 1px solid #ddd;width:800px;height: 140px;margin-top: 10px;padding:10px 50px;box-sizing: border-box;">
            <!-- 金额 -->
            <div style="display:flex;justify-content: flex-end;align-items: center;">
              <div style="font-size: 16px;color: #000;">总计：</div>
              <div style="color: #ff4400;font-size: 32px;font-weight: bold;">
                <span style="color: #ff6600;font-size: 16px;">￥</span>
                {{CurrentSubmitItem.currentPrice?CurrentSubmitItem.currentPrice:currentTab==1?list[0].currentPrice:list2[0].currentPrice}}
              </div>
            </div>
            <!-- 支付类型 -->
            <div style="display: flex;justify-content: space-between;">
              <div style="display: flex;">
                <!-- 支付宝 -->
                <!-- <div :style="currentZfType==1?'border: 1px solid #21a557;':''" @click="currentZf(1)"
                  style="margin-right: 20px;width: 118px;height: 42px;cursor: pointer;box-sizing: border-box;position: relative;">
                  <img style="width:100%;height:100%"
                    src='../../../assets/recharge/2e45cd5bbd0b7ab4522eb725d4fc8556_432be578715a4dca9664904e4b91dd2a.png'
                    alt="">
                  <div v-if="currentZfType==1" style="position:absolute;right: -3px;top: -1px;">
                    <svg width="23px" height="23px">
                      <polygon points="0,0 20,0 20,20" style="fill:#21a557; stroke:#21a557; stroke-width:1;" />
                      <polyline points="9,5 12,10 20,4" style="fill:none;stroke:#fff;stroke-width:2" />
                    </svg>
                  </div>
                </div> -->
                <!-- 微信 -->
                <div :style="currentZfType==2?'border: 1px solid #21a557;':''" @click="currentZf(2)"
                  style="margin-right: 20px;width: 118px;height: 42px;cursor: pointer;box-sizing: border-box;position: relative;">
                  <img style="width:100%;height:100%"
                    src='../../../assets/recharge/cc62ab4e61a5f238ae9f77e48c8aa47c_01d2fc35f1cf47a6b84f2379fd0defdf.png'
                    alt="">
                  <div v-if="currentZfType==2" style="position:absolute;right: -3px;top: -1px;">
                    <svg width="23px" height="23px">
                      <polygon points="0,0 20,0 20,20" style="fill:#21a557; stroke:#21a557; stroke-width:1;" />
                      <polyline points="9,5 12,10 20,4" style="fill:none;stroke:#fff;stroke-width:2" />
                    </svg>
                  </div>
                </div>
              </div>
              <div @click="submit"
                style="height: 44px;width: 160px;font-size: 18px;text-align: center;line-height: 44px;color: #fff;background: #ff6600;cursor: pointer;width: 160px;">
                立即支付</div>
            </div>
            <!-- 问题反馈 -->
            <div style="font-size: 12px;display: flex;justify-content: flex-end;align-items: center;margin-top: 15px;">
              <div style="font-size: 12px;color: #666;">支付遇到问题？联系客服获得帮助。</div>
              <div
                style="    height: 20px;width: 82px;line-height: 22px;background: #fff;border: 1px solid #e6e6e6;text-align: center;cursor: pointer;"
                class="el-icon-headset">在线咨询</div>
            </div>
          </div>
        </div>
      </el-card>
    </div>
    <!-- 弹框 -->
    <el-dialog @close="close" title="二维码支付" :visible.sync="centerDialogVisible" style="min-width:40%" width="40%"
      center>
      <div v-loading="loading">
        <!-- 提示 -->
        <div style="display:flex;justify-content:center">扫一扫付款（元）</div>
        <!-- 金额 -->
        <div style="font-size: 26px;font-weight: 700;color: #f60;display:flex;justify-content:center">
          {{CurrentSubmitItem.currentPrice?CurrentSubmitItem.currentPrice:list[0].currentPrice}}</div>
        <!-- 二维码 -->
        <div
          style="width: 168px;height: auto;min-height: 168px;margin: 0 auto;position:relative;padding: 6px;border: 1px solid #d3d3d3;box-shadow: 1px 1px 1px #ccc;">
          <vue-qr :size="168" :margin="0" :auto-color="true" :dot-scale="1" :text="codeUrl" />
          <!-- 提示 -->
          <div style="padding: 10px 0 6px;display: flex;justify-content: center;align-items: center;">
            <img style="height:28px;"
              src="../../../assets/recharge/2395364079ec1ed080756cef701d4096_T1bdtfXfdiXXXXXXXX.png" alt="">
            <div style="margin-left: 10px;">
              <div>打开手机{{currentZfType==1?'支付宝':'微信'}}</div>
              <div>扫一扫继续付款</div>
            </div>
          </div>
          <img style="width: 13rem;height: 9rem;position: absolute;top: -1rem;right: -14rem;border-radius: 100%;"
            src="../../../assets/Icon/df4b7560ce2dab8071fef01d4818a5e4_t01fc14333eb3db53d0.jpg" alt="">
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button style="background-color:#21a557" type="primary" @click="centerDialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>
<script>
  import { mapState } from "vuex";
  import VueQr from 'vue-qr';
  import { number } from "echarts/lib/export";
  export default {
    components: {
      VueQr
    },
    data() {
      return {
        currentTab: 1,// 当前选中tab
        codeUrl: '',// 返回的二维码地址
        numTime: '',// 轮询事件
        timer: '',// 定时器
        loading: false,// 二维码加载
        centerDialogVisible: false,// 弹框
        currentIndex: 0,// 当前选中列表下标
        currentZfType: 1,// 当前支付方式
        CurrentSubmitItem: '',// 选中列表数据
        userInfo: {},// 个人信息
        options: [{
          value: '1',
          label: '使用优惠卷'
        }, {
          value: '0',
          label: '不使用优惠卷'
        }],
        value: '',
        list: [{
          dateFormats: '1',// 期限-月
          masterCopy: '25',// 原件
          currentPrice: '19.9',// 现价
          discount: '8',// 折扣
        }, {
          dateFormats: '3',// 期限-月
          masterCopy: '75',// 原件
          currentPrice: '59.9',// 现价
          discount: '85',// 折扣
        }, {
          dateFormats: '6',// 期限-月
          masterCopy: '150',// 原件
          currentPrice: '99.9',// 现价
          discount: '7',// 折扣
        }, {
          dateFormats: '12',// 期限-月
          masterCopy: '300',// 原件
          currentPrice: '149.9',// 现价
          discount: '5',// 折扣
        }],
        list2: [{
          dateFormats: '300',// 数量
          masterCopy: '60',// 原价
          currentPrice: '30',// 现价
          discount: '50',// 折扣
        }, {
          dateFormats: '1000',// 期限-月
          masterCopy: '120',// 原件
          currentPrice: '89',// 现价
          discount: '100',// 折扣
        }, {
          dateFormats: '2000',// 期限-月
          masterCopy: '240',// 原件
          currentPrice: '149',// 现价
          discount: '200',// 折扣
        }, {
          dateFormats: '6250',// 期限-月
          masterCopy: '700',// 原件
          currentPrice: '399',// 现价
          discount: '500',// 折扣
        }],
      }
    },
    computed: {
      currentList() {
        console, log(this.list)
        return this.list
      }
    },
    created() {
      this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
      console.log(this.user)
    },
    computed: {
      ...mapState({
        user: state => state.userInfo,
      })
    },
    destroyed() {
      // 关闭轮询
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = ''
        this.numTime = 0;
      }
    },
    methods: {
      // 切换tab
      clickTab(type) {
        if (type == this.currentTab) return
        this.currentTab = type;
        if (type == 1) {
          this.CurrentSubmitItem = this.list[0]
        } else if (type == 2) {
          this.CurrentSubmitItem = this.list2[0]
        }
        this.currentIndex = 0
        console.log(type)
      },
      // 支付结果轮询
      interval(url) {
        this.numTime = 0;
        if (this.timer) {
          clearInterval(this.timer);
          this.numTime = 0;
          this.timer = ''
        }
        this.timer = setInterval(() => {
          this.numTime += 1;
          this.$http
            .post(url)
            .then((res) => {
              // console.log(res);
              if (res.data.code == 200) {
                // if (this.numTime = 3) {
                //   res.data.data.timeIsOver = '0'
                // }
                window.localStorage.setItem(
                  "userInfo",
                  JSON.stringify(res.data.data)
                );
                this.$store.commit('getUserInfo', res.data.data)
                console.log(res.data.data.timeIsOver)
                this.userInfo = JSON.parse(window.localStorage.getItem("userInfo"))
                if (this.user.timeIsOver == 0) {
                  this.$router.push("/chongzhi")
                  clearInterval(this.timer);
                }
              } else {
                clearInterval(this.timer);
                this.timer = ''
                this.numTime = 0;
                return;
              }
            })
            .catch((err) => {
              console.log(err);
            });
          if (this.numTime >= 30) {
            clearInterval(this.timer);
            this.timer = ''
            this.numTime = 0;
            return;
          }
        }, 2000);
      },
      // 获取支付宝支付地址
      getSubmitUrl() {
        if (this.currentTab == 2) return
        this.$http.post(`/pay/paynow`, {}, {
          params: {
            orderCount: this.CurrentSubmitItem.dateFormats || this.list[0].dateFormats,// 选择充值时间
            amout: this.CurrentSubmitItem.currentPrice || this.list[0].currentPrice,// 充值金额
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            // 后台设置支付宝返回的是form表单
            let divForm = document.getElementsByTagName('divform')
            if (divForm.length) {
              document.body.removeChild(divForm[0])
            }
            const div = document.createElement('divform');
            div.innerHTML = res.data.data.form;
            document.body.appendChild(div);
            document.forms[0].acceptCharset = 'UTF-8';
            document.forms[0].setAttribute('target', '_blank')
            console.log(document.forms[0])
            document.forms[0].submit()
            this.interval(`/user/getuser`)
            this.loading = false
          }
        }).catch(err => {
          console.log(err)
          this.loading = false
        })
      },
      // 获取微信支付url
      getWeixin() {
        let obj = {}
        // 会员充值
        if (this.currentTab == 1) {
          obj = {}
          obj.payWay = '0'
          obj.orderCount = this.CurrentSubmitItem.dateFormats || this.list[0].dateFormats// 选择充值时间
          obj.amout = this.CurrentSubmitItem.currentPrice || this.list[0].currentPrice// 充值金额
        }
        // 短信充值
        else if (this.currentTab == 2) {
          obj = {}
          obj.payWay = 1
          obj.amout = this.CurrentSubmitItem.currentPrice || this.list2[0].currentPrice
          obj.messCount = Number(this.CurrentSubmitItem.dateFormats) + Number(this.CurrentSubmitItem.discount)
        }
        this.loading = true
        this.$http.post(`/pay/wechatPay`, {}, {
          params: obj
        }).then(res => {
          if (res.data.code = 200) {
            this.loading = false
            this.interval(`/user/getuser`)
            this.codeUrl = res.data.data.codeUrl
          } else {
            if (res.data.msg) {
              this.$message.error(res.data.msg)
            }
          }
          console.log(res)
        }).catch(err => {
          console.log(err)
        })
      },
      // 选中列表项
      currentItem(item, index) {
        this.CurrentSubmitItem = item
        this.currentIndex = index
      },
      // 选中支付类型
      currentZf(type) {
        this.currentZfType = type
      },
      // 支付
      submit() {
        switch (Number(this.currentZfType)) {
          case 1:
            console.log('支付宝支付')
            this.getSubmitUrl()
            break;
          case 2:
            console.log('微信支付')
            this.getWeixin()
            this.centerDialogVisible = true
            break;
        }
      },
      // 弹框关闭回调
      close() {
        console.log('关闭弹框')
        this.$store.commit('getUserData')
      },
      // 兑换优惠卷
      clickYHJ() {
        this.$message('该功能暂未开发，敬请期待')
      },
    },
  }
</script>

<style scoped>
  /deep/.el-dialog--center {
    width: 650px !important;
  }

  .currentTab {
    background-color: #409EFF;
    color: #fff;
    border: none;
  }

  .tabItem {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    border: 1px solid #ccc;
    box-sizing: border-box;
  }
</style>